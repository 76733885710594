import React from "react";
import axios from "../../axios";
import s from "./EmployeeSelector.module.scss";

function EmployeeSelector({
  employees,
  selectedEmployeeId,
  setSelectedEmployeeId,
  onEmployeeDeleted,
}) {
  const handleDelete = async () => {
    if (!selectedEmployeeId) {
      alert("Выберите сотрудника для удаления.");
      return;
    }

    if (window.confirm("Вы уверены, что хотите удалить этого сотрудника?")) {
      try {
        const response = await axios.delete(
          `/employees/${selectedEmployeeId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          onEmployeeDeleted(selectedEmployeeId);
        } else {
          alert("Ошибка при удалении сотрудника.");
        }
      } catch (error) {
        console.error("Ошибка при удалении сотрудника:", error);
        alert("Ошибка при удалении сотрудника: " + error.message);
      }
    }
  };

  return (
    <div className={s.employeeSelectorContainer}>
      <label className={s.employeeSelectorLabel}>Выберите сотрудника: </label>
      <div className={s.selectorWrapper}>
        <select
          value={selectedEmployeeId}
          onChange={(e) => setSelectedEmployeeId(e.target.value)}
          className={s.employeeSelectorSelect}
        >
          <option value="" disabled>
            Выберите сотрудника
          </option>
          {employees.map((employee) => (
            <option key={employee._id} value={employee._id}>
              {employee.username}
            </option>
          ))}
        </select>
        <button
          onClick={handleDelete}
          className={s.deleteButton}
          disabled={!selectedEmployeeId}
        >
          Удалить
        </button>
      </div>
    </div>
  );
}

export default EmployeeSelector;
