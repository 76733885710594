import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "./context/AuthContext";
import { CatamaranProvider } from "./context/CatamaranContext";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <CatamaranProvider>
        <App />
      </CatamaranProvider>
    </AuthProvider>
  </React.StrictMode>
);
serviceWorkerRegistration.register();
