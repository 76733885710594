import React, { useState } from "react";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import s from "./LoginForm.module.scss";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      const response = await axios.post("/login", { username, password });
      if (response.data.accessToken) {
        login(response.data);
        switch (response.data.role) {
          case "manager":
            navigate(`/manager/${response.data.id}`);
            break;
          case "employee":
            navigate(`/employee/${response.data.id}`);
            break;
          default:
            setErrorMessage("Недопустимая роль пользователя.");
            break;
        }
      } else {
        console.error("Токен не был получен от сервера");
        setErrorMessage("Проблема с аутентификацией, попробуйте ещё раз.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setErrorMessage(
        error.response?.data.message || "Login failed. Please try again."
      );
    }
  };

  return (
    <form className={s.loginFormContainer} onSubmit={handleSubmit}>
      {errorMessage && <div className={s.errorMessage}>{errorMessage}</div>}
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className={s.inputField}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className={s.inputField}
      />
      <button type="submit" className={s.submitButton}>
        Login
      </button>
    </form>
  );
};

export default LoginForm;
