import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3001/api",
  headers: {
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const registerUser = async (userData) => {
  try {
    const response = await instance.post("/register", userData);
    console.log(
      "Token received during registration:",
      response.data.accessToken
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Status code:", error.response.status);
      throw new Error(
        `Error: ${error.response.data.message || "Registration failed"}`
      );
    } else if (error.request) {
      console.error("No response:", error.request);
      throw new Error("No response from server");
    } else {
      console.error("Error:", error.message);
      throw new Error(`Error creating request: ${error.message}`);
    }
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await instance.post("/login", userData);

    if (response.data.accessToken && response.data.role) {
      console.log("Token received during login:", response.data.accessToken);
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("userId", response.data.id);

      return response.data;
    } else {
      console.error("Токен не был получен от сервера");
      throw new Error("Проблема с аутентификацией, попробуйте ещё раз.");
    }
  } catch (error) {
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Status code:", error.response.status);
      throw new Error(
        `Error: ${error.response.data.message || "Login failed"}`
      );
    } else if (error.request) {
      console.error("No response:", error.request);
      throw new Error("No response from server");
    } else {
      console.error("Error:", error.message);
      throw new Error(`Error creating request: ${error.message}`);
    }
  }
};

export default instance;
