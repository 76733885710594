import React from "react";
import Catamarans from "../../components/Catamarans/Catamarans";
import s from "./EmployeePage.module.scss";

function EmployeePage() {
  return (
    <div className={s.employeePageContainer}>
      <h1>Страница Сотрудника</h1>

      <div className={s.catamaransContainer}>
        <Catamarans />
      </div>
    </div>
  );
}

export default EmployeePage;
