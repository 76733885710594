import React, { useState } from "react";
import axios from "../../axios";
import s from "./RegisterForm.module.scss";

const RegisterForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const role = "manager";
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    try {
      await axios.post("/register", { username, password, role });
      setSuccessMessage("Registration successful!");
    } catch (error) {
      console.error("Error registering:", error);
      setErrorMessage(
        error.response?.data.message || "Registration failed. Please try again."
      );
    }
  };

  return (
    <form className={s.registerFormContainer} onSubmit={handleSubmit}>
      {errorMessage && <div className={s.errorMessage}>{errorMessage}</div>}
      {successMessage && (
        <div className={s.successMessage}>{successMessage}</div>
      )}
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        className={s.inputField}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className={s.inputField}
      />
      <button type="submit" className={s.submitButton}>
        Register as Manager
      </button>
    </form>
  );
};

export default RegisterForm;
