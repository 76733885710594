import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser || !currentUser.token) {
    return <Navigate to="/" replace />;
  }

  const isAuthorized = allowedRoles.includes(currentUser.role);

  return isAuthorized ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
