import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const id = localStorage.getItem("userId");

    if (token && role && id) {
      setCurrentUser({ token, role, id });
    }
    setLoading(false);
  }, []);

  const login = (data) => {
    if (data.accessToken && data.role && data.id) {
      localStorage.setItem("token", data.accessToken);
      localStorage.setItem("role", data.role);
      localStorage.setItem("userId", data.id);
      setCurrentUser({ token: data.accessToken, role: data.role, id: data.id });
    } else {
      console.error("Ошибка: токен, роль или ID не получены.");
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    setCurrentUser(null);
  };

  const value = { currentUser, login, logout, loading };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
