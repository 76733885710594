import React, { useState } from "react";
import axios from "../../axios";
import s from "./EmployeeCreationForm.module.scss";

const EmployeeCreationForm = ({ onEmployeeCreated }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "/employees",
        { username, password, role: "employee" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        onEmployeeCreated(response.data.employee);
        setUsername("");
        setPassword("");
        setError("");
      } else {
        throw new Error("Сервер вернул неожиданный ответ");
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Ошибка при создании сотрудника. Попробуйте снова."
      );
    }
  };

  return (
    <form className={s.formContainer} onSubmit={handleSubmit}>
      <h2 className={s.formTitle}>Создание сотрудника</h2>
      {error && <div className={s.formError}>{error}</div>}
      <div>
        <label>
          Имя пользователя (логин):
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className={s.formInput}
          />
        </label>
      </div>
      <div>
        <label>
          Пароль:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={s.formInput}
          />
        </label>
      </div>
      <button type="submit" className={s.formButton}>
        Создать сотрудника
      </button>
    </form>
  );
};

export default EmployeeCreationForm;
