import React, { useState } from "react";
import s from "./AuthPage.module.scss";
import LoginForm from "../../components/LoginForm";
import RegisterForm from "../../components/RegisterForm";

function AuthPage() {
  const [isLoginActive, setIsLoginActive] = useState(true);

  return (
    <div className={s.authPageContainer}>
      <div className={s.formContainer}>
        <h1>Авторизация</h1>
        <div className={s.tabs}>
          <button
            onClick={() => setIsLoginActive(true)}
            className={isLoginActive ? s.activeTab : ""}
          >
            Login
          </button>
          <button
            onClick={() => setIsLoginActive(false)}
            className={!isLoginActive ? s.activeTab : ""}
          >
            Register
          </button>
        </div>
        {isLoginActive ? <LoginForm /> : <RegisterForm />}
      </div>
    </div>
  );
}

export default AuthPage;
