import React, { useEffect, useState, useCallback } from "react";
import axios from "../../axios";
import s from "./ManagerPage.module.scss";
import EmployeeCreationForm from "../../components/EmployeeCreationForm";
import EmployeeSelector from "../../components/EmployeeSelector";
import CatamaranManager from "../../components/CatamaranManager";
import RentalReports from "../../components/RentalReports";
import { useAuth } from "../../context/AuthContext";
import { useParams } from "react-router-dom";

function ManagerPage() {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);

  const fetchEmployees = useCallback(async () => {
    const token = currentUser?.token;
    if (!token) {
      console.log("Нет токена для авторизации");
      return;
    }
    try {
      const response = await axios.get(`/employees/list?managerId=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.length > 0) {
        setSelectedEmployeeId(response.data[0]._id);
      }
      setEmployees(response.data);
    } catch (error) {
      console.error("Ошибка при загрузке списка сотрудников:", error);
    }
  }, [currentUser, id]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  const handleEmployeeCreated = (newEmployee) => {
    if (!newEmployee.id) {
      console.error("Данные нового сотрудника не содержат 'id':", newEmployee);
      return;
    }
    setEmployees((prevEmployees) => [
      ...prevEmployees,
      { ...newEmployee, _id: newEmployee.id },
    ]);
    setSelectedEmployeeId(newEmployee.id);
    setIsFormVisible(false);
  };

  const handleEmployeeDeleted = (deletedEmployeeId) => {
    setEmployees((prevEmployees) =>
      prevEmployees.filter((employee) => employee._id !== deletedEmployeeId)
    );
    if (selectedEmployeeId === deletedEmployeeId) {
      setSelectedEmployeeId("");
    }
  };

  const showForm = () => setIsFormVisible(true);
  const hideForm = () => setIsFormVisible(false);

  return (
    <div className={s.managerPageContainer}>
      <button onClick={showForm} className={s.createButton}>
        Создать сотрудника
      </button>
      {isFormVisible && (
        <div className={s.modal}>
          <div className={s.modalContent}>
            <button onClick={hideForm} className={s.closeButton}>
              ×
            </button>
            <EmployeeCreationForm onEmployeeCreated={handleEmployeeCreated} />
          </div>
        </div>
      )}
      <EmployeeSelector
        employees={employees}
        selectedEmployeeId={selectedEmployeeId}
        setSelectedEmployeeId={setSelectedEmployeeId}
        onEmployeeDeleted={handleEmployeeDeleted}
      />
      <CatamaranManager selectedEmployeeId={selectedEmployeeId} />
      <RentalReports managerId={id} />
    </div>
  );
}

export default ManagerPage;
