import React, { useState } from "react";
import axios from "../../axios";
import s from "./RentalReports.module.scss";
import { useAuth } from "../../context/AuthContext";

function RentalReports() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [catamaranName, setCatamaranName] = useState(""); // Новое состояние для фильтрации по названию
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { currentUser } = useAuth();

  const fetchReports = async () => {
    if (!startDate || !endDate) {
      setError("Пожалуйста, укажите начальную и конечную даты.");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const response = await axios.get("/catamarans/report", {
        params: {
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(
            new Date(endDate).setHours(23, 59, 59, 999)
          ).toISOString(),
          managerId: currentUser.id,
          catamaranName, // Добавляем имя катамарана в параметры запроса
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      console.log("Response data:", response.data); // Добавим логирование
      setReport(response.data);
      if (!response.data) {
        setError("Отчеты за указанный период отсутствуют.");
      }
    } catch (error) {
      console.error("Ошибка при получении отчетов:", error);
      setError("Ошибка при получении отчетов: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  function formatDuration(seconds) {
    if (isNaN(seconds) || seconds === null || seconds === undefined) {
      return "N/A";
    }
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins} мин. ${secs} сек.`;
  }

  return (
    <div className={s.rentalReportsContainer}>
      <h1 className={s.reportsTitle}>Отчеты о прокатах</h1>
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        placeholder="Начальная дата"
        className={s.dateInput}
      />
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        placeholder="Конечная дата"
        className={s.dateInput}
      />
      <input
        type="text"
        value={catamaranName}
        onChange={(e) => setCatamaranName(e.target.value)}
        placeholder="Название катамарана"
        className={s.textInput}
      />
      <button
        onClick={fetchReports}
        disabled={loading}
        className={s.fetchButton}
      >
        {loading ? "Загрузка..." : "Получить отчеты"}
      </button>
      {error && <p className={s.errorMessage}>{error}</p>}
      {report && (
        <div>
          <h2>Общий отчет</h2>
          <div>
            Всего прокатов:{" "}
            {report.reduce(
              (acc, emp) =>
                acc +
                emp.catamarans.reduce((acc, cat) => acc + cat.totalRentals, 0),
              0
            )}
          </div>
          <div>
            Общая продолжительность:
            <span className={s.duration}>
              {formatDuration(
                report.reduce(
                  (acc, emp) =>
                    acc +
                    emp.catamarans.reduce(
                      (acc, cat) => acc + cat.totalDuration,
                      0
                    ),
                  0
                )
              )}
            </span>
          </div>
          <ul>
            {report && report.length > 0 ? (
              report.map((employee, empIndex) => (
                <li key={empIndex}>
                  <h3>Сотрудник: {employee.employeeName}</h3>
                  {employee.catamarans.map((catamaran, catIndex) => (
                    <div key={catIndex}>
                      <h4>
                        <strong>{catamaran.name}</strong> - Дата проката:
                        {catamaran.date}
                      </h4>
                      <div>Всего прокатов: {catamaran.totalRentals}</div>
                      <div>
                        Общая продолжительность:
                        <span className={s.duration}>
                          {formatDuration(catamaran.totalDuration)}
                        </span>
                      </div>
                      {catamaran.rentals.map((rental, rentalIndex) => (
                        <ul key={rentalIndex}>
                          <li>
                            Начало:
                            <span className={s.startTime}>
                              {new Date(rental.startTime).toLocaleTimeString()}
                            </span>
                            , Окончание:
                            {rental.endTime
                              ? new Date(rental.endTime).toLocaleTimeString()
                              : "Прокат не завершен"}
                            , Продолжительность:
                            <span
                              className={`${s.duration} ${
                                rental.duration > rental.timeLimit
                                  ? s.durationExceeded
                                  : ""
                              }`}
                            >
                              {formatDuration(rental.duration)}
                            </span>
                            {rental.returnComment && (
                              <div className={s.returnComment}>
                                Возврат: {rental.returnComment}
                              </div>
                            )}
                          </li>
                        </ul>
                      ))}
                    </div>
                  ))}
                </li>
              ))
            ) : (
              <p>Нет данных о катамаранах для этого отчета.</p>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default RentalReports;
