import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import ManagerPage from "./pages/ManagerPage";
import EmployeePage from "./pages/EmployeePage";
import ProtectedRoute from "./components/ProtectedRoute";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthPage />} />
        <Route
          path="/manager/:id"
          element={
            <ProtectedRoute
              component={ManagerPage}
              allowedRoles={["manager"]}
            />
          }
        />
        <Route
          path="/employee/:id"
          element={
            <ProtectedRoute
              component={EmployeePage}
              allowedRoles={["employee", "manager"]}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
