import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { useAuth } from "../../context/AuthContext";
import s from "./CatamaranManager.module.scss";

function CatamaranManager({ selectedEmployeeId }) {
  const [catamarans, setCatamarans] = useState([]);
  const [newCatamaranName, setNewCatamaranName] = useState("");
  const [newFixedTimeLimit, setNewFixedTimeLimit] = useState("");
  const { currentUser } = useAuth();

  useEffect(() => {
    async function fetchCatamarans() {
      try {
        const response = await axios.get("/catamarans", {
          headers: { Authorization: `Bearer ${currentUser.token}` },
          params: { employeeId: selectedEmployeeId },
        });
        setCatamarans(response.data);
      } catch (err) {
        console.error("Ошибка при загрузке катамаранов:", err);
      }
    }

    if (selectedEmployeeId) {
      fetchCatamarans();
    }
  }, [selectedEmployeeId, currentUser.token]);

  const handleAddCatamaran = async () => {
    if (!newCatamaranName) return;

    try {
      const response = await axios.post(
        "/catamarans",
        {
          name: newCatamaranName,
          employeeId: selectedEmployeeId,
          fixedTimeLimit: newFixedTimeLimit
            ? parseInt(newFixedTimeLimit, 10) * 60
            : null,
        },
        { headers: { Authorization: `Bearer ${currentUser.token}` } }
      );
      setCatamarans([...catamarans, response.data]);
      setNewCatamaranName("");
      setNewFixedTimeLimit("");
    } catch (err) {
      console.error("Ошибка при добавлении катамарана:", err);
    }
  };

  const handleDeleteCatamaran = async (catId) => {
    try {
      await axios.delete(`/catamarans/${catId}`, {
        headers: { Authorization: `Bearer ${currentUser.token}` },
      });
      setCatamarans(catamarans.filter((cat) => cat._id !== catId));
    } catch (err) {
      console.error("Ошибка при удалении катамарана:", err);
    }
  };

  return (
    <div className={s.catamaranManagerContainer}>
      <h1>Список катамаранов</h1>
      <input
        type="text"
        value={newCatamaranName}
        onChange={(e) => setNewCatamaranName(e.target.value)}
        placeholder="Введите название катамарана"
        className={s.catamaranInput}
      />
      <input
        type="number"
        value={newFixedTimeLimit}
        onChange={(e) => setNewFixedTimeLimit(e.target.value)}
        placeholder="Введите фиксированное время в минутах"
        className={s.catamaranInput}
      />
      <button onClick={handleAddCatamaran} className={s.catamaranButton}>
        Добавить катамаран
      </button>
      <ul className={s.catamaranList}>
        {catamarans.map((cat) => (
          <li key={cat._id} className={s.catamaranItem}>
            {cat.name}{" "}
            {cat.fixedTimeLimit
              ? `(Фиксированное время: ${cat.fixedTimeLimit / 60} минут)`
              : ""}
            <button onClick={() => handleDeleteCatamaran(cat._id)}>
              Удалить
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CatamaranManager;
