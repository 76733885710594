import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import axios from "../axios";
import { useAuth } from "./AuthContext";
import { saveData, getData } from "../utils/indexedDBUtils";

const CatamaranContext = createContext();

export function useCatamarans() {
  return useContext(CatamaranContext);
}

export const CatamaranProvider = ({ children }) => {
  const [catamarans, setCatamarans] = useState([]);
  const [error, setError] = useState("");
  const [timeStates, setTimeStates] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCatId, setCurrentCatId] = useState(null);
  const [tempReturnComment, setTempReturnComment] = useState("");
  const [returnInProgress, setReturnInProgress] = useState({});
  const { currentUser } = useAuth();
  const intervalRef = useRef(null);

  const [timeLimit, setTimeLimit] = useState(45 * 60);

  const fetchCatamarans = useCallback(async () => {
    if (!currentUser || !currentUser.token) {
      return;
    }

    try {
      const response = await axios.get("/catamarans", {
        headers: { Authorization: `Bearer ${currentUser.token}` },
      });
      setCatamarans(response.data);
      initializeTimeStates(response.data);
    } catch (err) {
      setError("Ошибка при загрузке катамаранов: " + err.message);
    }
  }, [currentUser]);

  useEffect(() => {
    fetchCatamarans();
  }, [fetchCatamarans]);

  const updateTimers = useCallback(() => {
    const now = Date.now();
    setTimeStates((prev) => {
      return Object.keys(prev).reduce((newState, catId) => {
        const state = prev[catId];
        if (state.startTime && !state.finished && !state.paused) {
          newState[catId] = {
            ...state,
            elapsedTime:
              state.elapsedBeforePause +
              Math.floor((now - new Date(state.startTime).getTime()) / 1000),
          };
        } else {
          newState[catId] = state;
        }
        return newState;
      }, {});
    });
  }, [setTimeStates]);

  useEffect(() => {
    intervalRef.current = setInterval(updateTimers, 1000);
    return () => clearInterval(intervalRef.current);
  }, [updateTimers]);

  useEffect(() => {
    saveData("timeStates", timeStates);
  }, [timeStates]);

  useEffect(() => {
    saveData("timeLimit", timeLimit.toString());
  }, [timeLimit]);

  useEffect(() => {
    (async () => {
      const savedTimeStates = await getData("timeStates");
      if (savedTimeStates) {
        setTimeStates(savedTimeStates);
      }
      const savedTimeLimit = await getData("timeLimit");
      if (savedTimeLimit) {
        setTimeLimit(parseInt(savedTimeLimit, 10));
      }
    })();
  }, []);

  const initializeTimeStates = (data) => {
    const initialStates = data.reduce((acc, cat) => {
      const activeRental = cat.rentals.find((rental) => !rental.endTime);
      if (activeRental) {
        const elapsedBeforePause = activeRental.elapsedTime || 0;
        const startTime = new Date(activeRental.startTime).getTime();
        const now = Date.now();
        const elapsedTime =
          Math.floor((now - startTime) / 1000) + elapsedBeforePause;
        acc[cat._id] = {
          rentalId: activeRental._id,
          startTime: activeRental.startTime,
          elapsedTime,
          elapsedBeforePause,
          finished: false,
          paused: activeRental.paused,
          counter: activeRental.counter,
          timeLimit: activeRental.timeLimit,
        };
      } else {
        acc[cat._id] = {
          timer: null,
          elapsedTime: 0,
          elapsedBeforePause: 0,
          finished: false,
          returnTime: null,
          startTime: null,
          rentalId: null,
          paused: false,
          counter: 1,
          baseTimeLimit: cat.timeLimit,
        };
      }
      return acc;
    }, {});
    setTimeStates((prev) => ({ ...prev, ...initialStates }));
  };

  const endRental = useCallback(
    async (catId, rentalId) => {
      if (!currentUser || !currentUser.token) {
        return;
      }
      rentalId = rentalId || timeStates[catId]?.rentalId;
      if (!rentalId) {
        console.error("rentalId is undefined");
        return;
      }
      const endTime = new Date().toISOString();
      const counter = timeStates[catId]?.counter || 1;
      const currentTimeLimit = timeStates[catId]?.timeLimit || timeLimit;

      try {
        const response = await axios.post(
          `/catamarans/${catId}/rentals/${rentalId}/return`,
          { endTime, counter, timeLimit: currentTimeLimit },
          { headers: { Authorization: `Bearer ${currentUser.token}` } }
        );
        if (response.data) {
          setTimeStates((prev) => ({
            ...prev,
            [catId]: {
              ...prev[catId],
              finished: true,
              paused: false,
              timer: null,
              elapsedTime: 0,
              elapsedBeforePause: 0,
              startTime: null,
              rentalId: null,
              counter: 1,
              timeLimit: timeLimit,
            },
          }));
          return response.data;
        }
      } catch (error) {
        setError(`Ошибка при окончании проката: ${error.message}`);
        console.error("Error ending rental:", error);
        throw error;
      }
    },
    [currentUser, timeLimit, timeStates]
  );

  const startRental = useCallback(
    async (catId) => {
      if (!currentUser || !currentUser.token) {
        return;
      }

      // Проверка: если катамаран уже запущен, то не начинать новый прокат
      if (
        timeStates[catId] &&
        timeStates[catId].startTime &&
        !timeStates[catId].finished &&
        !timeStates[catId].paused
      ) {
        setError(`Катамаран ${catId} уже запущен`);
        return;
      }

      const startTime = new Date().toISOString();
      const date = new Date().toISOString();
      const counter = timeStates[catId]?.counter || 1;
      const catamaran = catamarans.find((cat) => cat._id === catId);
      const effectiveTimeLimit =
        (catamaran.fixedTimeLimit || timeLimit) * counter;

      // Завершение любого незавершённого проката
      if (timeStates[catId]?.rentalId && !timeStates[catId]?.finished) {
        try {
          await endRental(catId, timeStates[catId].rentalId);
        } catch (error) {
          setError(`Ошибка при завершении старого проката: ${error.message}`);
          console.error("Error ending previous rental:", error);
        }
      }

      try {
        const response = await axios.post(
          `/catamarans/${catId}/rent`,
          { startTime, date, timeLimit: effectiveTimeLimit, counter },
          { headers: { Authorization: `Bearer ${currentUser.token}` } }
        );
        if (response.data && response.data.rentalId) {
          setTimeStates((prev) => ({
            ...prev,
            [catId]: {
              ...prev[catId],
              startTime,
              rentalId: response.data.rentalId,
              elapsedTime: 0,
              elapsedBeforePause: 0,
              finished: false,
              timeLimit: effectiveTimeLimit,
            },
          }));
          return response.data.rentalId;
        }
      } catch (error) {
        setError(`Ошибка при начале проката: ${error.message}`);
        console.error("Error starting rental:", error);
        return null;
      }
    },
    [currentUser, timeLimit, catamarans, timeStates, endRental]
  );

  const returnCatamaran = useCallback(
    async (catId, rentalId, returnComment) => {
      if (!currentUser || !currentUser.token) {
        return;
      }

      if (timeStates[catId]?.timer) {
        clearInterval(timeStates[catId].timer);
      }
      const returnTime = new Date().toISOString();
      const counter = timeStates[catId]?.counter || 1;
      const currentTimeLimit = timeStates[catId]?.timeLimit || timeLimit;
      try {
        const response = await axios.post(
          `/catamarans/${catId}/return`,
          {
            rentalId,
            returnComment,
            returnTime,
            counter,
            timeLimit: currentTimeLimit,
          },
          { headers: { Authorization: `Bearer ${currentUser.token}` } }
        );
        if (response.data) {
          setTimeStates((prev) => ({
            ...prev,
            [catId]: {
              timer: null,
              rentalId: null,
              finished: true,
              elapsedTime: prev[catId]?.elapsedTime || 0,
              returnComment,
              returnTime,
              timeLimit: timeLimit,
              counter: 1,
            },
          }));
        }
      } catch (error) {
        setError(`Ошибка при возврате катамарана: ${error.message}`);
        console.error("Error returning catamaran:", error);
      } finally {
        setReturnInProgress((prev) => ({
          ...prev,
          [catId]: false,
        }));
      }
    },
    [currentUser, timeLimit, timeStates]
  );

  const openReturnModal = (catId) => {
    setCurrentCatId(catId);
    setTempReturnComment(timeStates[catId]?.returnComment || "");
    setIsModalOpen(true);
  };

  const closeReturnModal = () => {
    setIsModalOpen(false);
  };

  const submitReturn = async () => {
    if (currentCatId && tempReturnComment.trim()) {
      await returnCatamaran(
        currentCatId,
        timeStates[currentCatId].rentalId,
        tempReturnComment
      );
      closeReturnModal();
      setReturnInProgress((prev) => ({
        ...prev,
        [currentCatId]: false,
      }));
    } else {
      setError("Необходимо ввести причину возврата.");
    }
  };

  const updateCounterInDB = async (catId, rentalId, counter) => {
    const catamaran = catamarans.find((cat) => cat._id === catId);
    const newTimeLimit = (catamaran.fixedTimeLimit || timeLimit) * counter;

    try {
      await axios.patch(
        `/catamarans/${catId}/rentals/${rentalId}/counter`,
        { counter, timeLimit: newTimeLimit },
        { headers: { Authorization: `Bearer ${currentUser.token}` } }
      );
    } catch (error) {
      setError(`Ошибка при обновлении счётчика: ${error.message}`);
      console.error("Error updating counter:", error);
    }
  };

  const incrementCounter = (catId) => {
    setTimeStates((prev) => {
      const currentCounter = prev[catId]?.counter || 1;
      const newCounter = currentCounter + 1;
      const catamaran = catamarans.find((cat) => cat._id === catId);
      const newTimeLimit = (catamaran.fixedTimeLimit || timeLimit) * newCounter;
      if (timeStates[catId]?.rentalId) {
        updateCounterInDB(catId, timeStates[catId].rentalId, newCounter); // Обновляем счётчик и лимит времени в базе данных
      }
      return {
        ...prev,
        [catId]: {
          ...prev[catId],
          counter: newCounter,
          timeLimit: newTimeLimit,
        },
      };
    });
  };

  const decrementCounter = (catId) => {
    setTimeStates((prev) => {
      const currentCounter = prev[catId]?.counter || 1;
      const newCounter = currentCounter > 1 ? currentCounter - 1 : 1;
      const catamaran = catamarans.find((cat) => cat._id === catId);
      const newTimeLimit = (catamaran.fixedTimeLimit || timeLimit) * newCounter;
      if (timeStates[catId]?.rentalId) {
        updateCounterInDB(catId, timeStates[catId].rentalId, newCounter); // Обновляем счётчик и лимит времени в базе данных
      }
      return {
        ...prev,
        [catId]: {
          ...prev[catId],
          counter: newCounter,
          timeLimit: newTimeLimit,
        },
      };
    });
  };

  const value = {
    catamarans,
    error,
    timeStates,
    setTimeStates,
    fetchCatamarans,
    startRental,
    endRental,
    returnCatamaran,
    openReturnModal,
    closeReturnModal,
    submitReturn,
    isModalOpen,
    setIsModalOpen,
    tempReturnComment,
    setTempReturnComment,
    currentCatId,
    returnInProgress,
    setReturnInProgress,
    timeLimit,
    setTimeLimit,
    incrementCounter,
    decrementCounter,
  };

  return (
    <CatamaranContext.Provider value={value}>
      {children}
    </CatamaranContext.Provider>
  );
};
