import React, { useCallback, useState, useEffect, useRef, memo } from "react";
import s from "./Catamarans.module.scss";
import { useCatamarans } from "../../context/CatamaranContext";

const CatamaranItem = memo(
  ({
    cat,
    timeStates,
    handleStartTimer,
    handleStopTimer,
    openReturnModal,
    incrementCounter,
    decrementCounter,
    timeLimit,
    isStopping,
  }) => {
    const { _id, name, fixedTimeLimit } = cat;
    const counter = timeStates[_id]?.counter || 1;
    const elapsedTime = timeStates[_id]?.elapsedTime || 0;
    const effectiveLimit = (fixedTimeLimit || timeLimit) * counter;
    const isTimeExceeded = elapsedTime > effectiveLimit;

    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const sec = seconds % 60;
      return `${minutes} минут ${sec} секунд`;
    };

    return (
      <li key={_id} className={s.catamaranItem}>
        <div className={s.catamaranName}>
          {name}
          <div className={s.counterContainer}>
            <button
              className={s.counterButton}
              onClick={() => decrementCounter(_id)}
            >
              -
            </button>
            <span className={s.counterValue}>{counter}</span>
            <button
              className={s.counterButton}
              onClick={() => incrementCounter(_id)}
            >
              +
            </button>
          </div>
        </div>
        <div className={s.buttonRow}>
          <button
            className={s.startRentalButton}
            onClick={() => handleStartTimer(_id)}
            disabled={!!timeStates[_id]?.startTime && !timeStates[_id]?.paused}
          >
            Старт
          </button>
          <button
            className={s.endRentalButton}
            onClick={() => handleStopTimer(_id)}
            disabled={isStopping[_id] || !timeStates[_id]?.elapsedTime}
          >
            Стоп
          </button>
        </div>
        <div className={s.rentalTimeContainer}>
          <span className={isTimeExceeded ? s.rentalTimeExpired : s.rentalTime}>
            {formatTime(elapsedTime)}
          </span>
        </div>
        <button
          onClick={() => openReturnModal(_id)}
          disabled={!timeStates[_id]?.elapsedTime || timeStates[_id]?.finished}
        >
          Возврат катамарана
        </button>
      </li>
    );
  }
);

function Catamarans() {
  const {
    catamarans,
    error,
    timeStates,
    setTimeStates,
    startRental,
    endRental,
    openReturnModal,
    timeLimit,
    setTimeLimit,
    incrementCounter,
    decrementCounter,
    isModalOpen, // Добавлено для отображения модального окна
    closeReturnModal, // Добавлено для закрытия модального окна
    tempReturnComment, // Добавлено для управления комментариями
    setTempReturnComment, // Добавлено для обновления комментариев
    submitReturn, // Добавлено для отправки возврата
  } = useCatamarans();

  const [isStarting, setIsStarting] = useState({});
  const [isStopping, setIsStopping] = useState({});

  const intervalRef = useRef(null);

  const updateTimers = useCallback(() => {
    const now = Date.now();
    setTimeStates((prev) =>
      Object.keys(prev).reduce((newState, catId) => {
        const state = prev[catId];
        if (state.startTime && !state.finished && !state.paused) {
          newState[catId] = {
            ...state,
            elapsedTime:
              state.elapsedBeforePause +
              Math.floor((now - new Date(state.startTime).getTime()) / 1000),
          };
        } else {
          newState[catId] = state;
        }
        return newState;
      }, {})
    );
  }, [setTimeStates]);

  useEffect(() => {
    intervalRef.current = setInterval(updateTimers, 1000);
    return () => clearInterval(intervalRef.current);
  }, [updateTimers]);

  const handleStartTimer = async (catId) => {
    if (isStarting[catId]) return;
    setIsStarting((prev) => ({ ...prev, [catId]: true }));

    if (
      timeStates[catId] &&
      timeStates[catId].startTime &&
      !timeStates[catId].finished &&
      !timeStates[catId].paused
    ) {
      alert(`Катамаран ${catId} уже запущен`);
      setIsStarting((prev) => ({ ...prev, [catId]: false }));
      return;
    }

    const currentState = timeStates[catId];

    if (currentState?.paused) {
      const startTime = new Date().toISOString();
      setTimeStates((prev) => ({
        ...prev,
        [catId]: {
          ...prev[catId],
          paused: false,
          startTime,
        },
      }));
      setIsStarting((prev) => ({ ...prev, [catId]: false }));
    } else if (!currentState?.timer) {
      try {
        const rentalId = await startRental(catId);
        if (rentalId) {
          const startTime = new Date().toISOString();
          setTimeStates((prev) => ({
            ...prev,
            [catId]: {
              ...prev[catId],
              rentalId,
              elapsedTime: 0,
              finished: false,
              startTime,
            },
          }));
        }
      } catch (error) {
        console.error(`Failed to start rental for ${catId}:`, error);
      } finally {
        setIsStarting((prev) => ({ ...prev, [catId]: false }));
      }
    }
  };

  const handleStopTimer = async (catId) => {
    if (isStopping[catId]) return;
    setIsStopping((prev) => ({ ...prev, [catId]: true }));

    const state = timeStates[catId];
    if (!state) return;

    const { rentalId, paused, finished } = state;
    if (finished) {
      setTimeStates((prev) => ({
        ...prev,
        [catId]: {
          timer: null,
          elapsedTime: 0,
          elapsedBeforePause: 0,
          finished: false,
          paused: false,
          startTime: null,
          rentalId: null,
        },
      }));
      setIsStopping((prev) => ({ ...prev, [catId]: false }));
    } else if (!paused) {
      setTimeStates((prev) => ({
        ...prev,
        [catId]: {
          ...prev[catId],
          paused: true,
          elapsedBeforePause: prev[catId]?.elapsedTime,
        },
      }));
      setIsStopping((prev) => ({ ...prev, [catId]: false }));
    } else if (paused) {
      try {
        if (!rentalId) {
          setIsStopping((prev) => ({ ...prev, [catId]: false }));
          return;
        }
        await endRental(catId, rentalId);
        setTimeStates((prev) => ({
          ...prev,
          [catId]: {
            ...prev[catId],
            finished: true,
            paused: false,
            timer: null,
            elapsedTime: 0,
            elapsedBeforePause: 0,
            startTime: null,
            rentalId: null,
            counter: 1,
          },
        }));
      } catch (error) {
      } finally {
        setIsStopping((prev) => ({ ...prev, [catId]: false }));
      }
    }
  };

  const handleTimeLimitChange = (limit) => {
    setTimeLimit(limit);
  };

  return (
    <div className={s.catamaranContainer}>
      <div className={s.header}>
        <h1>Управление катамаранами</h1>
        <div className={s.timeLimitButtons}>
          <button
            className={timeLimit === 35 * 60 ? s.active : ""}
            onClick={() => handleTimeLimitChange(35 * 60)}
          >
            35 минут
          </button>
          <button
            className={timeLimit === 45 * 60 ? s.active : ""}
            onClick={() => handleTimeLimitChange(45 * 60)}
          >
            45 минут
          </button>
        </div>
      </div>

      {error && <p className={s.error}>{error}</p>}
      <ul className={s.catamaranList}>
        {catamarans.map((cat) => (
          <CatamaranItem
            key={cat._id}
            cat={cat}
            timeStates={timeStates}
            handleStartTimer={handleStartTimer}
            handleStopTimer={handleStopTimer}
            openReturnModal={openReturnModal}
            incrementCounter={incrementCounter}
            decrementCounter={decrementCounter}
            timeLimit={timeLimit}
            isStopping={isStopping}
          />
        ))}
      </ul>
      {isModalOpen && (
        <div className={s.modalOverlay}>
          <div className={s.modalContent}>
            <h2>Укажите причину возврата</h2>
            <textarea
              className={s.modalTextarea}
              value={tempReturnComment}
              onChange={(e) => setTempReturnComment(e.target.value)}
            />
            <button onClick={submitReturn} className={s.modalSubmitButton}>
              Подтвердить возврат
            </button>
            <button onClick={closeReturnModal} className={s.modalCloseButton}>
              Закрыть
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Catamarans;
